<template>
    <BT-Blade-Item
        bladeName="order-sheet"
        :bladesData="bladesData"
        :changeToggle="changeToggle"
        :loadingMsg="loadingMsg"
        navigation="order-sheets"
        :onNew="() => { return { orderSheetName: null, orderSheetItems: [] } }"
        :onPullSuccessAsync="pullItem"
        :refreshToggle="refreshToggle"
        titleProp="orderSheetName"
        :actualUsedHeight="127"
        :defaultBladeWidth="450">
        <template v-slot="{ item, data, save, isChanged }">
            <BT-Field-String
                v-if="data.isNew"
                v-model="item.orderSheetName"
                isEditing
                label="Sheet Name" />
            <BT-List-Endless
                v-if="!data.isNew"
                :activeClass="null"
                :canAdd="data.isEditing"
                :canSearch="false"
                canSearchLocal
                :items="orderSheetItems"
                label="Sheet Items"
                :onAddAsync="startAdd"
                :onFilter="filterItems"
                :ripple="false"
                :searchProps="['supplyAgreement.buyer.companyName']"
                :actualUsedHeight="180">
                <template v-slot:title-left>
                    <v-btn v-if="isLengthyArray(item.orderSheetItems)" small @click="resetSheet(item)" class="mr-2 primary">Reset</v-btn>
                </template>
                <template v-slot:toolbar-right>
                    <v-btn small icon title="Export List To CSV" @click="exportToCSV">
                        <v-icon small>mdi-file-export</v-icon>
                    </v-btn>
                    <v-btn v-if="!data.isEditing" small @click="doFilter = !doFilter" icon title="Toggle filtering of unchecked sheet items">
                        <v-icon small>{{ doFilter ? 'mdi-filter-remove' : 'mdi-filter' }}</v-icon>
                    </v-btn>
                </template>
                <template v-slot="d">
                    <v-list-item-action class="mr-1">
                        <v-btn
                            v-if="!data.isEditing" 
                            :class="d.item.isDone ? 'success--text' : 'error--text'"
                            @click.stop="toggleSheetItem(d.item, isChanged)"
                            icon
                            small>
                            <v-icon small>{{ d.item.isDone ? 'mdi-check' : 'mdi-close' }}</v-icon>
                        </v-btn>
                        <v-btn v-else
                            class="error--text"
                            @click.stop="removeSheetItem(d.item)"
                            icon
                            small>
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ d.item.supplyAgreement.buyer.companyName }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-slide-x-transition hide-on-leave>
                            <v-row v-if="data.isEditing" class="mouse-over-show" dense>
                                <v-btn v-if="d.item.standingOrderID != null" small icon @click.stop="removeStandingOrder(d.item)" title="Remove Standing Order">
                                    <v-icon small>mdi-close</v-icon>
                                </v-btn>
                                <v-btn v-if="d.item.standingOrderID == null" small icon @click.stop="toggleStandingOrder(d.item)" title="Add Standing Order">
                                    <v-icon small>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn v-if="d.item.standingOrderID != null" small icon @click.stop="toggleStandingOrder(d.item)" title="Edit Standing Order">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                            </v-row>
                            <v-row v-else class="mouse-over-show" dense>
                                <v-slide-y-transition hide-on-leave group>
                                    <v-btn
                                        @click.stop="openExistingOrders(d.item)"
                                        icon
                                        key="1"
                                        small
                                        title="View Existing Orders">
                                        <v-icon small>mdi-list-box</v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click.stop="newOrder(d.item)"
                                        icon
                                        key="2"
                                        small
                                        title="Create New Customer Order">
                                        <v-icon small>mdi-plus</v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click.stop="repeatLastOrder(d.item)"
                                        icon
                                        key="3"
                                        small
                                        title="Copy Most Recent Order">
                                        <v-icon small>mdi-content-copy</v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click.stop="openSchedule(d.item)"
                                        icon
                                        key="5"
                                        small
                                        title="View Ordering Schedule">
                                        <v-icon small>mdi-book-clock</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="d.item.supplyAgreement.isSuspended"
                                        @click.stop
                                        icon
                                        key="6"
                                        small
                                        title="Account Suspended">
                                        <v-icon color="error" small>mdi-alert-circle</v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click.stop="instantiateOrder(d.item)"
                                        :disabled="d.item.standingOrderID == null"
                                        icon
                                        key="4"
                                        small
                                        title="Create Standing Order">
                                        <v-icon small>mdi-receipt-text-plus</v-icon>
                                    </v-btn>
                                </v-slide-y-transition>
                            </v-row>
                        </v-slide-x-transition>
                        
                        <!-- <v-menu
                            v-else
                            close-on-content-click
                            dense
                            left
                            :open-delay="300"
                            :open-on-hover="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-on="on" v-bind="attrs">
                                    <v-icon small>mdi-dots-horizontal-circle-outline</v-icon>
                                </v-btn>
                            </template>

                            <v-card height="200" width="200">
                                <v-container>
                                    <v-row dense>
                                        <v-col cols="6">
                                            <v-btn
                                                @click.stop="repeatLastOrder(item)"
                                                height="80"
                                                small
                                                width="80">
                                                <v-container dense class="text-center">
                                                    <v-icon class="pb-4 pt-2" size="20">mdi-content-copy</v-icon>
                                                    <p class="text-center">Copy Last</p>
                                                </v-container>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                @click.stop="instantiateOrder(item)"
                                                :disabled="item.standingOrderID == null"
                                                height="80"
                                                small
                                                width="80">
                                                <v-container dense class="text-center">
                                                    <v-icon class="pb-4 pt-2" size="20">mdi-receipt-text-plus</v-icon>
                                                    <p class="text-center">Standing</p>
                                                </v-container>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="6">
                                            <v-btn
                                                @click.stop="openSchedule(item)"
                                                height="80"
                                                small
                                                width="80">
                                                <v-container dense class="text-center">
                                                    <v-icon class="pb-4 pt-2" size="20">mdi-book-clock</v-icon>
                                                    <p class="text-center">Schedule</p>
                                                </v-container>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                @click.stop="newOrder(item)"
                                                height="80"
                                                small
                                                width="80">
                                                <v-container dense class="text-center">
                                                    <v-icon class="pb-4 pt-2" size="30">mdi-plus</v-icon>
                                                    <p class="text-center">New</p>
                                                </v-container>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-menu> -->
                    </v-list-item-action>
                </template>
            </BT-List-Endless>

            <BT-Snack v-model="msg" />

            <BT-Select-List-Dialog
                hideButton
                itemText="buyer.companyName"
                itemValue="id"
                multiple
                navigation="customers"
                :onFilter="list => { return list.filter(y => !currentItems.some(x => x.supplyAgreementID == y.id)) }"
                returnObject
                :showToggle="showToggle"
                small
                @ok="addItems"
                width="350" />

            <BT-Select-Dialog
                v-if="currentOrderSheetItem != null"
                hideButton
                listItemValue="id"
                listItemText="standingOrderName"
                navigation="standing-customer-orders"
                :params="standingOrderParams"
                :showToggle="standingOrderToggle"
                small
                title="Select Standing Order"
                v-model="currentOrderSheetItem.standingOrderID"
                width="350"
                @change="selectStandingOrder" />

        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Order-Sheet',
    components: {
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
        BTSelectListDialog: () => import('~components/BT-Select-List-Dialog.vue'),
    },
    data: function() {
        return {
            changeToggle: false,
            currentItems: [],
            currentOrderSheetItem: null,
            currentSheet: null,
            doFilter: false,
            loadingMsg: null,
            msg: null,
            orderSheetItems: [],
            refreshToggle: false,
            showToggle: false,
            standingOrderParams: null, //{ customerIDs: item.supplyAgreement.buyer.id }
            standingOrderToggle: false,
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        async removeSheetItem(item) {
            try {
                this.loadingMsg = 'Removing';
                var ind = this.orderSheetItems.findIndex(x => x.id == item.id);
                await this.$BlitzIt.api.delete('order-sheet-items', item);
                this.orderSheetItems.splice(ind, 1);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async removeStandingOrder(item) {
            try {
                this.loadingMsg = 'Removing Standing Order';
                item.standingOrderID = null;
                var res = await this.$BlitzIt.api.patch('order-sheet-items', item);
                item.rowVersion = res.rowVersion;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async addItems(items) {
            try {
                if (this.isLengthyArray(items)) {
                    for (let i = 0; i < items.length; i++) {
                        const sup = items[i];
                        
                        this.loadingMsg = `Adding ${i} of ${items.length} items`;

                        var sheetItem = {
                            id: null,
                            orderSheetID: this.currentSheet.id,
                            rowVersion: null,
                            supplyAgreementID: sup.id,
                            supplyAgreement: sup,
                            standingOrderID: null,
                            isDone: false
                        };

                        var res = await this.$BlitzIt.store.post('order-sheet-items', sheetItem);

                        sheetItem.id = res.id;
                        sheetItem.rowVersion = res.rowVersion;

                        this.orderSheetItems.push(sheetItem);
                    }

                    this.orderSheetItems.sort(firstBy(x => x.supplyAgreement.buyer.companyName, { ignoreCase: true }));
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        exportToCSV() {
            this.generateCSV(
                this.orderSheetItems,
                [{ title: 'Customer Name', key: 'supplyAgreement.buyer.companyName' },
                    { title: 'Standing Order', key: 'standingOrderID', valueFunction: (v) => { return v ? 'Yes' : null; }}],
                'OrderSheetItems' + '.csv',
                'OrderSheetItems');
        },
        filterItems(list) {
            return this.doFilter ? list.filter(x => !x.isDone) : list;
        },
        async openSchedule(item) {
            this.bladesData.openBlade({ 
                bladeName: 'standing-orders-schedule', 
                data: {
                    id: item.supplyAgreementID, //item.standingOrderID,
                    agreementID: item.supplyAgreementID,
                    agreement: item.supplyAgreement,
                    customerID: item.supplyAgreement.buyer.id,
                } 
            });
        },
        async instantiateOrder(item) {
            this.bladesData.openBlade({ 
                bladeName: 'customer-order',
                data: {
                    id: 'new',
                    standingOrderID: item.standingOrderID,
                    agreementID: item.supplyAgreementID,
                    customerID: item.supplyAgreement.buyer.id, 
                } 
            });
        },
        async newOrder(item) {
            this.bladesData.openBlade({ 
                bladeName: 'customer-order',
                data: {
                    id: 'new',
                    agreementID: item.supplyAgreementID,
                    customerID: item.supplyAgreement.buyer.id, 
                } 
            });
        },
        async pullItem(item) {
            if (item != null) {
                this.currentSheet = item;
                this.orderSheetItems = item.orderSheetItems;
                this.orderSheetItems.sort(firstBy(x => x.supplyAgreement.buyer.companyName, { ignoreCase: true }));
            }
            
            return item;
        },
        async repeatLastOrder(item) {
            this.bladesData.openBlade({ 
                bladeName: 'customer-order',
                data: {
                    id: 'new',
                    repeatLast: true,
                    agreementID: item.supplyAgreementID,
                    customerID: item.supplyAgreement.buyer.id, 
                } 
            });
        },
        async resetSheet(item) {
            if (await this.$confirm('Are you sure you want to reset this sheet?')) {
                try {
                    await this.$BlitzIt.api.patch('order-sheet-items', null, null, `/Patch/Reset/${item.id}`)
                    this.refreshToggle = !this.refreshToggle;
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        },
        async openExistingOrders(item) {
            this.bladesData.openBlade({ 
                bladeName: 'customer-orders', 
                data: { 
                    customerID: item.supplyAgreement.buyer.id, 
                    agreementID: item.supplyAgreementID 
                } 
            });
        },
        startAdd(list) {
            this.currentItems = list;
            this.showToggle = !this.showToggle;
        },
        async toggleSheetItem(sheetItem, isChanged) {
            try {
                var wasAlreadyChanged = isChanged;
                this.loadingMsg = sheetItem.isDone ? 'Unchecking item' : 'Checking item';

                var copy = this.copyDeep(sheetItem);
                copy.isDone = !copy.isDone;

                var res = await this.$BlitzIt.store.patch('order-sheet-items', copy);

                sheetItem.rowVersion = res.rowVersion;
                sheetItem.isDone = res.isDone;

                if (!wasAlreadyChanged) {
                    this.changeToggle = !this.changeToggle;
                }
            }
            catch (err) {
                sheetItem.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectStandingOrder(stdID) {
            try {
                this.loadingMsg = 'Adding Standing Order';
                this.currentOrderSheetItem.standingOrderID = stdID;
                var res = await this.$BlitzIt.api.patch('order-sheet-items', this.currentOrderSheetItem);
                this.currentOrderSheetItem.rowVersion = res.data.data.rowVersion;
            }
            finally {
                this.loadingMsg = null;
            }
            // this.currentOrderSheetItem.standingOrderID = stdID;
        },
        toggleStandingOrder(oItem) {
            this.currentOrderSheetItem = oItem;
            this.standingOrderParams = { customerIDs: oItem.supplyAgreement.buyer.id };
            this.standingOrderToggle = !this.standingOrderToggle;
        }
    }
}
</script>